.video-about-b{
    width: 100vw !important;
    height: 100vh !important;
}
.v-d1{
    width: 100% !important;
    height: 100% !important;
}
.bg_image{
background-image: url('../../img/contentimg/Untitled-4.jpg');
}