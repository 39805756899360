.text-pp{
text-align: center;
}
.pp-1{
    padding: 20px 0px;
}
.privacy-d2{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 64px 0px;
}
.privacy-d3{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    
}