* {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
/* scroll top */

.d-1p {
  opacity: 0;
  visibility: hidden;
  transform: translateX(100px);
  transition: all 0.5s ease;
}

/*  */
.ds-1{}
.d-ss{
  display: none;
}

/* water-head */

.head-th {
  font-size: 329px ;
  transform: translate(70px, 40px);
  font-family: 'Montserrat', sans-serif;}

/*  */
@media only screen and  (max-width: 400px) {

  .head-th {
    /* text-align: center; */

    font-size:70px !important ;
    transform: unset !important;
  }

}



@media only screen and (max-width: 1600px) {
  .head-th {
    /* text-align: center; */

    font-size: 200px ;
    transform: translate(70px, 40px);
  }
  .span-op {
    font-size: 33px !important;
 
}
.head-u {

  display: flex;
  gap: 1rem;
}
}

@media (max-width: 1200px) {
  .head-th {
    /* text-align: center; */

    font-size: 150px ;
    transform: translate(70px, 40px);
  }
  .head-u {

    display: flex;
    gap: 2rem;
  }
}
@media (max-width: 750px) {
  .head-th {
    /* text-align: center; */

    font-size:150px ;
    transform: unset !important;
  }
  .span-op {
    font-size: 33px !important;
    transform: translate(0px, 0px) !important;


}
.head-u {
  cursor: default;
  display: flex;
  gap:  unset !important;
  justify-content: center;
}
}
@media (max-width: 650px) {
  .head-th {
    /* text-align: center; */

    font-size:100px ;
    transform: unset !important;
  }
  .span-op {
    font-size: 33px !important;
    transform: translate(0px, 0px) !important;


}
.head-u {
  cursor: default;
  display: flex;
  gap:  unset !important;
  justify-content: center;
}
}


.span-op{

  /* position: absolute;
  right: 331px;
  top: 82px; */
  font-size: 40px;
  transform: translate(35px, 64px);
}



.head-u{
cursor: default;
display: flex;

}
@media (max-width: 700px) {

.nav-hh{
display: none !important;
}
.nav-mo{
  display: unset !important;
}
}
@media (max-width: 1040px) {

  .head-u {

    justify-content: center;
}
  
  }
.mobile-menu-nav{

  
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.header2{
  padding: 15px 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 25;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;
}
.nav-mo{
  display: none ;
}

.bb-none{

width: fit-content;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: 0!important;
}
.open-tab-mob{
  color: white;

  
}
.list-font-ss{
  font-size: 20px;
  text-transform: uppercase;
  white-space: pre;
}
.mobile-tab-ul{
  display: flex;
  gap: 26px;
  flex-direction: column;
}
.m-close{

  display: flex;
  justify-content: flex-end;
}
#about{
padding: 50px 0px;

}


@media (max-width: 770px) {

.swiper-slide {
  width: 500px !important;

}

}

@media (max-width: 450px) {

  .swiper-slide {
    width: 300px !important;
  
  }
  
  }




  ::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-thumb {
  background:  #191023;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #fff;
}